import { createRouter, createWebHistory } from 'vue-router';
import Index from '@/views/index.vue';
import ContentCategories from '@/views/content_categories.vue';
import ArtCategories from '@/views/art_categories.vue';
import SearchPage from '@/views/search.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import NominateSomeone from '@/views/nominate-someone.vue';
import AdvertiseWithUs from '@/views/advertise-with-us.vue';
import AdminLogin from '@/views/admin-login.vue';
import Admin from '@/views/admin.vue';
import Interview from '@/views/interview.vue';
import PostPage from '@/views/post-page.vue';
import AboutUs from '@/views/about-us.vue';
import SimilarPosts from '@/views/similar-posts.vue';
import TermsOfService from '@/views/terms-of-service.vue';

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: '/about-history-of-art/',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/terms-of-service/',
        name: 'terms-of-service',
        component: TermsOfService
    },
    {
        path: '/recommend-someone/',
        name: 'recommend-someone',
        component: NominateSomeone
    },
    {
        path: '/advertise-with-us/',
        name: 'advertise-with-us',
        component: AdvertiseWithUs
    },
    {
        path: '/interview/',
        name: 'interview',
        component: Interview
    },
    {
        path: '/admin-login/',
        name: 'admin-login',
        component: AdminLogin
    },
    {
        path: '/admin/',
        name: 'admin',
        component: Admin
    },
    {
        path: '/post/:slug',
        name: "Post",
        component: PostPage,
    },
    {
        path: '/category/:slug?',
        name: "ContentCategories",
        component: ContentCategories,
    },
    {
        path: '/artcategory/:slug?',
        name: "ArtCategories",
        component: ArtCategories,
    },
    {
        path: '/similar/:slug?/:pid?',
        name: "Similar",
        component: SimilarPosts,
    },
    {
        path: '/search/:stext?',
        name: "search",
        component: SearchPage,
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
