<template>

    <div class="w-full p-4 md:p-10 gap-4 flex flex-col items-center justify-start" :class="{'dark': this.$store.state.use_dark}" style="font-family: Questrial, Shabnam;" :data-theme="this.$store.state.app_theme">
        <div class="card shadow border p-4 w-full flex flex-row justify-between items-center">
            <div class="flex flex-row items-center justify-center gap-2">
                <Logo class="hidden md:flex" :class="{'md:h-auto': logo_size == 'free', 'md:h-24': logo_size == 'normal', 'md:h-20': logo_size == 'small', 'md:h-12': logo_size == 'tiny', 'h-auto': logo_mobile_size == 'free', 'h-24': logo_mobile_size == 'normal', 'h-20': logo_mobile_size == 'small', 'h-12': logo_mobile_size == 'tiny'}" :use_black="true" />
                <div class="hidden md:flex divider divider-horizontal p-0 m-0"></div>
                <p class="text-md md:text-lg text-gray-500">Admin Panel</p>
            </div>
            <div class="flex flex-row items-center justify-center md:gap-2">
                <div class="tooltip" data-tip="Open the main page of the site in a new tab">
                    <router-link to="/" target="_blank" class="btn btn-square btn-ghost">
                        <OpenInNewIcon :size="20" class="md:hidden pointer-events-none text-gray-700" />
                        <OpenInNewIcon :size="24" class="hidden md:inline-flex pointer-events-none text-gray-700" />
                    </router-link>
                </div>
                <div class="tooltip" data-tip="Reload admin panel">
                    <button @click="refreshItems" class="btn btn-square btn-ghost">
                        <RefreshIcon :size="20" class="md:hidden pointer-events-none text-gray-700" />
                        <RefreshIcon :size="24" class="hidden md:inline-flex pointer-events-none text-gray-700" />
                    </button>
                </div>
                <div class="tooltip" data-tip="Logout from admin account">
                    <button @click="logout" class="btn btn-square btn-ghost">
                        <LogoutIcon :size="20" class="md:hidden pointer-events-none text-gray-700" />
                        <LogoutIcon :size="24" class="hidden md:inline-flex pointer-events-none text-gray-700" />
                    </button>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-start justify-center w-full">
            <div class="flex flex-row items-center justify-center gap-2 w-full">
                <p class="font-bold whitespace-nowrap text-base">Current Tab: </p>
                <select v-model="current_tab" class="select select-bordered w-full text-base">
                    <option value="0">Interview Data</option>
                    <option value="1">Recommend Someone</option>
                    <option value="2">Invitation Codes</option>
                    <option value="3">Content Categories</option>
                    <option value="4">Artist Categories</option>
                    <option value="5">Interview Questions 1</option>
                    <option value="6">Interview Questions 2</option>
                    <option value="7">Subscribers</option>
                    <option value="8">Advertise With Us</option>
                    <option value="9">Starters</option>
                    <option value="10">More</option>
                </select>
            </div>
            <div role="tablist" class="tabs tabs-lifted w-full mt-4">
                <!-- tab 0 - interview data -->
                <input v-model="current_tab" value="0" type="radio" name="admin_tabs" aria-label="Interview Data" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Interview Data</p>
                            <!-- search section -->
                            <label class="input input-bordered flex items-center gap-2 w-full md:w-auto md:min-w-[30%]" v-if="admin_data && admin_data.interview_data && admin_data.interview_data.length > 0">
                                <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <input v-model="interview_search_text" type="text" class="grow" placeholder="Search" />
                                <button v-show="interview_search_text" @click="interview_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                    <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                </button>
                            </label>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full" v-if="admin_data && admin_data.interview_data && admin_data.interview_data.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.interview_data">
                                    <AdminInterviewItem
                                    v-if="!interview_search_text || (interview_search_text && (item.person.name.toLowerCase().includes(interview_search_text.toLowerCase()) || item.person.email.toLowerCase().includes(interview_search_text.toLowerCase()) || item.content_category.name.toLowerCase().includes(interview_search_text.toLowerCase()) || item.artist_category.name.toLowerCase().includes(interview_search_text.toLowerCase()) || item.views_count == interview_search_text ))"
                                    @delete_item="delete_admin_item" @details_dialog="open_interview_details_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.interview_data.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-lg table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Name</label>
                                            </button>
                                        </th>
                                        <th class="hidden md:table-cell text-center">
                                            <button @click="sort_asc = (sort_type == 2 ? !sort_asc : true); sort_type = 2; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 2}">
                                                <svg v-if="sort_type == 2 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 2 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Email</label>
                                            </button>
                                        </th>
                                        <th class="text-center">Content Category</th>
                                        <th class="text-center">Artist Category</th>
                                        <th class="hidden md:table-cell text-center">
                                            <button @click="sort_asc = (sort_type == 3 ? !sort_asc : true); sort_type = 3; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 3}">
                                                <svg v-if="sort_type == 3 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 3 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Views</label>
                                            </button>
                                        </th>
                                        <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="18" class="md:hidden pointer-events-none" />
                                            <DotsHorizontalIcon :size="24" class="hidden md:inline-flex pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in admin_data.interview_data">
                                        <AdminInterviewItem
                                        v-if="!interview_search_text || (interview_search_text && (item.person.name.toLowerCase().includes(interview_search_text.toLowerCase()) || item.person.email.toLowerCase().includes(interview_search_text.toLowerCase()) || item.content_category.name.toLowerCase().includes(interview_search_text.toLowerCase()) || item.artist_category.name.toLowerCase().includes(interview_search_text.toLowerCase()) || item.views_count == interview_search_text ))"
                                        @delete_item="delete_admin_item" @details_dialog="open_interview_details_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.interview_data.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 1 - Recommend someone -->
                <input v-model="current_tab" value="1" type="radio" name="admin_tabs" aria-label="Recommend Someone" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Recommend Someone</p>
                            <!-- search section -->
                            <label class="input input-bordered flex items-center gap-2 w-full md:w-auto md:min-w-[30%]" v-if="admin_data && admin_data.person && admin_data.person.length > 0">
                                <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <input v-model="nominate_search_text" type="text" class="grow" placeholder="Search" />
                                <button v-show="nominate_search_text" @click="nominate_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                    <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                </button>
                            </label>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full" v-if="admin_data && admin_data.person && admin_data.person.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.person">
                                    <AdminPersonItem
                                    v-if="!nominate_search_text || (nominate_search_text && ( item.name.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.email.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.website.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.social_media.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.your_name.toLowerCase().includes(nominate_search_text.toLowerCase()) ))"
                                    @send_email_callback="send_mail_dialog_callback" @delete_item="delete_admin_item" :item="item" :index="sort_asc ? (index + 1) : (admin_data.person.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                <tr>
                                    <th class="text-center">
                                        <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                            <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                            <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                            <label class="pointer-events-none">#</label>
                                        </button>
                                    </th>
                                    <th class="text-center">
                                        <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                            <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                            <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                            <label class="pointer-events-none">Name</label>
                                        </button>
                                    </th>
                                    <th class="text-center">
                                        <button @click="sort_asc = (sort_type == 2 ? !sort_asc : true); sort_type = 2; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 2}">
                                            <svg v-if="sort_type == 2 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                            <svg v-if="sort_type == 2 && !sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                            <label class="pointer-events-none">Email</label>
                                        </button>
                                    </th>
                                    <th class="hidden md:table-cell text-center">Website</th>
                                    <th class="hidden md:table-cell text-center">Social Media</th>
                                    <th class="hidden md:table-cell text-center">
                                        <button @click="sort_asc = (sort_type == 3 ? !sort_asc : true); sort_type = 3; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 3}">
                                            <svg v-if="sort_type == 3 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                            <svg v-if="sort_type == 3 && !sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                            <label class="pointer-events-none">Introducer's name</label>
                                        </button>
                                    </th>
                                    <th class="text-center flex flex-row items-center justify-center">
                                        <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in admin_data.person">
                                        <AdminPersonItem
                                        v-if="!nominate_search_text || (nominate_search_text && ( item.name.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.email.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.website.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.social_media.toLowerCase().includes(nominate_search_text.toLowerCase()) || item.your_name.toLowerCase().includes(nominate_search_text.toLowerCase()) ))"
                                        @send_email_callback="send_mail_dialog_callback" @delete_item="delete_admin_item" :item="item" :index="sort_asc ? (index + 1) : (admin_data.person.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 2 - invitation codes -->
                <input v-model="current_tab" value="2" type="radio" name="admin_tabs" aria-label="Invitation Codes" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Invitation Codes</p>
                            <!-- search section -->
                            <label class="input input-bordered flex items-center gap-2 w-full md:w-auto md:min-w-[30%]" v-if="admin_data && admin_data.invite_codes && admin_data.invite_codes.length > 0">
                                <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                <input v-model="invite_codes_search_text" type="text" class="grow" placeholder="Search" />
                                <button v-show="invite_codes_search_text" @click="invite_codes_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                    <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                </button>
                            </label>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full" v-if="admin_data && admin_data.invite_codes && admin_data.invite_codes.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.invite_codes">
                                    <AdminInviteCodeItem
                                    v-if="!invite_codes_search_text || (invite_codes_search_text && ( item.person.name.toLowerCase().includes(invite_codes_search_text.toLowerCase()) || item.person.email.toLowerCase().includes(invite_codes_search_text.toLowerCase()) || item.invite_code.includes(invite_codes_search_text) ))"
                                    @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.invite_codes.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Name</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 2 ? !sort_asc : true); sort_type = 2; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 2}">
                                                <svg v-if="sort_type == 2 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 2 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Email</label>
                                            </button>
                                        </th>
                                        <th class="text-center">Code</th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 3 ? !sort_asc : true); sort_type = 3; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 3}">
                                                <svg v-if="sort_type == 3 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 3 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Creation date</label>
                                            </button>
                                        </th>
                                        <th class="text-center">Expire date</th>
                                        <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in admin_data.invite_codes">
                                        <AdminInviteCodeItem
                                        v-if="!invite_codes_search_text || (invite_codes_search_text && ( item.person.name.toLowerCase().includes(invite_codes_search_text.toLowerCase()) || item.person.email.toLowerCase().includes(invite_codes_search_text.toLowerCase()) || item.invite_code.includes(invite_codes_search_text) ))"
                                        @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.invite_codes.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 3 - content categories -->
                <input v-model="current_tab" value="3" type="radio" name="admin_tabs" aria-label="Content Categories" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Content Categories</p>
                            <div class="flex flex-row items-center justify-center gap-2 md:w-auto md:min-w-[35%]">
                                <!-- search section -->
                                <label class="input input-bordered flex items-center gap-2 w-full" v-if="admin_data && admin_data.content_categories && admin_data.content_categories.length > 0">
                                    <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <input v-model="content_category_search_text" type="text" class="grow" placeholder="Search" />
                                    <button v-show="content_category_search_text" @click="content_category_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                        <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </button>
                                </label>
                                <!-- add button -->
                                <button @click="show_add_edit_dialog('add', 'content_category')" class="btn flex flex-row items-center justify-center gap-2">
                                    <PlusIcon :size="18" class="pointer-events-none" />
                                    <span class="hidden md:inline-flex">Add</span>
                                </button>
                            </div>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full mt-4" v-if="admin_data && admin_data.content_categories && admin_data.content_categories.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.content_categories">
                                    <AdminContentCategoryItem
                                    v-if="!content_category_search_text || (content_category_search_text && ( item.name.toLowerCase().includes(content_category_search_text.toLowerCase()) ))"
                                    @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.content_categories.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Name</label>
                                            </button>
                                        </th>
                                        <th class="text-center">Url Name</th>
                                        <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="font-semibold">
                                    <template v-for="(item, index) in admin_data.content_categories">
                                        <AdminContentCategoryItem
                                        v-if="!content_category_search_text || (content_category_search_text && ( item.name.toLowerCase().includes(content_category_search_text.toLowerCase()) ))"
                                        @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.content_categories.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 4 - artist categories -->
                <input v-model="current_tab" value="4" type="radio" name="admin_tabs" aria-label="Artist Categories" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Artist Categories</p>
                            <div class="flex flex-row items-center justify-center gap-2 md:w-auto md:min-w-[35%]">
                                <!-- search section -->
                                <label class="input input-bordered flex items-center gap-2 w-full" v-if="admin_data && admin_data.artist_categories && admin_data.artist_categories.length > 0">
                                    <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <input v-model="artist_category_search_text" type="text" class="grow" placeholder="Search" />
                                    <button v-show="artist_category_search_text" @click="artist_category_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                        <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </button>
                                </label>
                                <!-- add button -->
                                <button @click="show_add_edit_dialog('add', 'artist_category')" class="btn flex flex-row items-center justify-center gap-2">
                                    <PlusIcon :size="18" class="pointer-events-none" />
                                    <span class="hidden md:inline-flex">Add</span>
                                </button>
                            </div>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full mt-4" v-if="admin_data && admin_data.artist_categories && admin_data.artist_categories.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.artist_categories">
                                    <AdminArtistCategoryItem
                                    v-if="!artist_category_search_text || (artist_category_search_text && ( item.name.toLowerCase().includes(artist_category_search_text.toLowerCase()) ))"
                                    @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.artist_categories.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Name</label>
                                            </button>
                                        </th>
                                        <th class="text-center">Url Name</th>
                                        <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="font-semibold">
                                    <template v-for="(item, index) in admin_data.artist_categories">
                                        <AdminArtistCategoryItem
                                        v-if="!artist_category_search_text || (artist_category_search_text && ( item.name.toLowerCase().includes(artist_category_search_text.toLowerCase()) ))"
                                        @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.artist_categories.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 5 - interview questions 1 -->
                <input v-model="current_tab" value="5" type="radio" name="admin_tabs" aria-label="Interview Questions 1" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Interview Questions 1</p>
                            <div class="flex flex-row items-center justify-center gap-2 md:w-auto md:min-w-[35%]">
                                <!-- search section -->
                                <label class="input input-bordered flex items-center gap-2 w-full" v-if="admin_data && admin_data.interview_questions_1 && admin_data.interview_questions_1.length > 0">
                                    <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <input v-model="interview_q_1_search_text" type="text" class="grow" placeholder="Search" />
                                    <button v-show="interview_q_1_search_text" @click="interview_q_1_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                        <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </button>
                                </label>
                                <!-- add button -->
                                <button @click="show_add_edit_dialog('add', 'interview_question_1', {content_categories: this.admin_data.content_categories})" class="btn flex flex-row items-center justify-center gap-2">
                                    <PlusIcon :size="18" class="pointer-events-none" />
                                    <span class="hidden md:inline-flex">Add</span>
                                </button>
                            </div>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full mt-4" v-if="admin_data && admin_data.interview_questions_1 && admin_data.interview_questions_1.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.interview_questions_1">
                                    <AdminInterviewQuestionsItem
                                    v-if="!interview_q_1_search_text || (interview_q_1_search_text && ( item.category.name.toLowerCase().includes(interview_q_1_search_text.toLowerCase()) || item.name.toLowerCase().includes(interview_q_1_search_text.toLowerCase()) || item.text.toLowerCase().includes(interview_q_1_search_text.toLowerCase()) ))"
                                    @delete_item="delete_admin_item" @edit_item="edit_interview_question_item" :item="item" :index="sort_asc ? (index + 1) : (admin_data.interview_questions_1.length - index)" :is_mobile="true" :question_type="1"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Category</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 2 ? !sort_asc : true); sort_type = 2; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 2}">
                                                <svg v-if="sort_type == 2 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 2 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Title</label>
                                            </button>
                                        </th>
                                        <th class="text-center">Text</th>
                                        <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="font-semibold">
                                    <template v-for="(item, index) in admin_data.interview_questions_1">
                                        <AdminInterviewQuestionsItem
                                        v-if="!interview_q_1_search_text || (interview_q_1_search_text && ( item.category.name.toLowerCase().includes(interview_q_1_search_text.toLowerCase()) || item.name.toLowerCase().includes(interview_q_1_search_text.toLowerCase()) || item.text.toLowerCase().includes(interview_q_1_search_text.toLowerCase()) ))"
                                        @delete_item="delete_admin_item" @edit_item="edit_interview_question_item" :item="item" :index="sort_asc ? (index + 1) : (admin_data.interview_questions_1.length - index)" :is_mobile="false" :question_type="1"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 6 - interview questions 2 -->
                <input v-model="current_tab" value="6" type="radio" name="admin_tabs" aria-label="Interview Questions 2" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Interview Questions 2</p>
                            <div class="flex flex-row items-center justify-center gap-2 md:w-auto md:min-w-[35%]">
                                <!-- search section -->
                                <label class="input input-bordered flex items-center gap-2 w-full" v-if="admin_data && admin_data.interview_questions_2 && admin_data.interview_questions_2.length > 0">
                                    <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <input v-model="interview_q_2_search_text" type="text" class="grow" placeholder="Search" />
                                    <button v-show="interview_q_2_search_text" @click="interview_q_2_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                        <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </button>
                                </label>
                                <!-- add button -->
                                <button @click="show_add_edit_dialog('add', 'interview_question_2', {content_categories: this.admin_data.content_categories})" class="btn flex flex-row items-center justify-center gap-2">
                                    <PlusIcon :size="18" class="pointer-events-none" />
                                    <span class="hidden md:inline-flex">Add</span>
                                </button>
                            </div>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full mt-4" v-if="admin_data && admin_data.interview_questions_2 && admin_data.interview_questions_2.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.interview_questions_2">
                                    <AdminInterviewQuestionsItem
                                    v-if="!interview_q_2_search_text || (interview_q_2_search_text && ( item.category.name.toLowerCase().includes(interview_q_2_search_text.toLowerCase()) || item.name.toLowerCase().includes(interview_q_2_search_text.toLowerCase()) || item.text.toLowerCase().includes(interview_q_2_search_text.toLowerCase()) ))"
                                    @delete_item="delete_admin_item" @edit_item="edit_interview_question_item" :item="item" :index="sort_asc ? (index + 1) : (admin_data.interview_questions_2.length - index)" :is_mobile="true" :question_type="2"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Category</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 2 ? !sort_asc : true); sort_type = 2; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 2}">
                                                <svg v-if="sort_type == 2 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 2 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Title</label>
                                            </button>
                                        </th>
                                        <th class="text-center">Text</th>
                                        <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="font-semibold">
                                    <template v-for="(item, index) in admin_data.interview_questions_2">
                                        <AdminInterviewQuestionsItem
                                        v-if="!interview_q_2_search_text || (interview_q_2_search_text && ( item.category.name.toLowerCase().includes(interview_q_2_search_text.toLowerCase()) || item.name.toLowerCase().includes(interview_q_2_search_text.toLowerCase()) || item.text.toLowerCase().includes(interview_q_2_search_text.toLowerCase()) ))"
                                        @delete_item="delete_admin_item" @edit_item="edit_interview_question_item" :item="item" :index="sort_asc ? (index + 1) : (admin_data.interview_questions_2.length - index)" :is_mobile="false" :question_type="2"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 7 - subscribers -->
                <input v-model="current_tab" value="7" type="radio" name="admin_tabs" aria-label="Subscribers" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Subscribers</p>
                            <!-- search section -->
                            <div class="flex flex-row items-center justify-center gap-2 md:w-auto md:min-w-[35%]" v-if="admin_data && admin_data.subscribers && admin_data.subscribers.length > 0">
                                <label class="input input-bordered flex items-center gap-2 w-full">
                                    <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <input v-model="subscribers_search_text" type="text" class="grow" placeholder="Search" />
                                    <button v-show="subscribers_search_text" @click="subscribers_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                        <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </button>
                                </label>
                            </div>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full mt-4" v-if="admin_data && admin_data.subscribers && admin_data.subscribers.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.subscribers">
                                    <AdminSubscriberItem
                                    v-if="!subscribers_search_text || (subscribers_search_text && ( item.email.toLowerCase().includes(subscribers_search_text.toLowerCase()) ))"
                                    :item="item" :index="sort_asc ? (index + 1) : (admin_data.subscribers.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-left">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-left w-full">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Email</label>
                                            </button>
                                        </th>
                                        <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="font-semibold">
                                    <template v-for="(item, index) in admin_data.subscribers">
                                        <AdminSubscriberItem
                                        @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" 
                                        v-if="!subscribers_search_text || (subscribers_search_text && ( item.email.toLowerCase().includes(subscribers_search_text.toLowerCase()) ))"
                                        :item="item" :index="sort_asc ? (index + 1) : (admin_data.subscribers.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 8 - advertise with us -->
                <input v-model="current_tab" value="8" type="radio" name="admin_tabs" aria-label="Advertise With Us" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Advertise With Us</p>
                            <!-- search section -->
                            <div class="flex flex-row items-center justify-center gap-2 md:w-auto md:min-w-[35%]" v-if="admin_data && admin_data.advertise && admin_data.advertise.length > 0">
                                <label class="input input-bordered flex items-center gap-2 w-full">
                                    <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <input v-model="advertise_search_text" type="text" class="grow" placeholder="Search" />
                                    <button v-show="advertise_search_text" @click="advertise_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                        <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </button>
                                </label>
                            </div>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full mt-4" v-if="admin_data && admin_data.advertise && admin_data.advertise.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.advertise">
                                    <AdminAdvertiseItem
                                    v-if="!advertise_search_text || (advertise_search_text && ( item.name.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.company_name.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.phone.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.email.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.website.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.description.toLowerCase().includes(advertise_search_text.toLowerCase()) ))"
                                    :item="item" :index="sort_asc ? (index + 1) : (admin_data.advertise.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-left">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-left">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Name</label>
                                            </button>
                                        </th>
                                        <th class="text-left">
                                            <button @click="sort_asc = (sort_type == 2 ? !sort_asc : true); sort_type = 2; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 2}">
                                                <svg v-if="sort_type == 2 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 2 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Name of Company</label>
                                            </button>
                                        </th>
                                        <th class="text-left">
                                            <button @click="sort_asc = (sort_type == 3 ? !sort_asc : true); sort_type = 3; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 3}">
                                                <svg v-if="sort_type == 3 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 3 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Email</label>
                                            </button>
                                        </th>
                                        <th class="text-left">
                                            <label class="pointer-events-none">Phone</label>
                                        </th>
                                        <th class="text-left">
                                            <label class="pointer-events-none">Website</label>
                                        </th>
                                        <th class="text-left w-full">
                                            <label class="pointer-events-none">Description</label>
                                        </th>
                                        <!-- <th class="text-center flex flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th> -->
                                    </tr>
                                </thead>
                                <tbody class="font-semibold">
                                    <template v-for="(item, index) in admin_data.advertise">
                                        <AdminAdvertiseItem
                                        v-if="!advertise_search_text || (advertise_search_text && ( item.name.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.company_name.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.phone.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.email.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.website.toLowerCase().includes(advertise_search_text.toLowerCase()) || item.description.toLowerCase().includes(advertise_search_text.toLowerCase()) ))"
                                        :item="item" :index="sort_asc ? (index + 1) : (admin_data.advertise.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 9 - starters -->
                <input v-model="current_tab" value="9" type="radio" name="admin_tabs" aria-label="Starters" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <div class="flex flex-col items-center justify-start w-full gap-4">
                        <!-- title and search -->
                        <div class="flex flex-col md:flex-row items-center justify-center gap-2 md:justify-between w-full">
                            <p class="text-gray-800 text-2xl">Starters</p>
                            <div class="flex flex-row items-center justify-end gap-2 md:w-auto md:min-w-[35%]">
                                <!-- search section -->
                                <label class="input input-bordered flex items-center gap-2 w-full" v-if="admin_data && admin_data.starters && admin_data.starters.length > 0">
                                    <svg class="w-5 h-5 stroke-current text-base-content" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 17L21 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 11C3 15.4183 6.58172 19 11 19C13.213 19 15.2161 18.1015 16.6644 16.6493C18.1077 15.2022 19 13.2053 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    <input v-model="starters_search_text" type="text" class="grow" placeholder="Search" />
                                    <button v-show="starters_search_text" @click="starters_search_text = ''" class="btn btn-sm btn-square btn-ghost">
                                        <svg class="w-5 h-5 stroke-current text-base-content" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </button>
                                </label>
                                <!-- add button -->
                                <button @click="show_add_edit_dialog('add', 'starter')" class="btn flex flex-row items-center justify-center gap-2">
                                    <PlusIcon :size="18" class="pointer-events-none" />
                                    <span class="hidden md:inline-flex">Add</span>
                                </button>
                            </div>
                        </div>
                        <!-- data -->
                        <div class="overflow-x-auto w-full mt-4" v-if="admin_data && admin_data.starters && admin_data.starters.length > 0">
                            <div class="flex md:hidden flex-col items-center justify-start w-full gap-4 overflow-x-hidden">
                                <template v-for="(item, index) in admin_data.starters">
                                    <AdminStarterItem
                                    v-if="!starters_search_text || (starters_search_text && ( item.text.toLowerCase().includes(starters_search_text.toLowerCase()) ))"
                                    @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.starters.length - index)" :is_mobile="true"
                                    />
                                </template>
                            </div>
                            <table class="hidden md:table table-xs md:table-md table-pin-rows table-zebra w-full">
                                <!-- head -->
                                <thead class="text-gray-600 md:text-base">
                                    <tr>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 0 ? !sort_asc : true); sort_type = 0; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 0}">
                                                <svg v-if="sort_type == 0 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 0 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">#</label>
                                            </button>
                                        </th>
                                        <th class="text-center">
                                            <button @click="sort_asc = (sort_type == 1 ? !sort_asc : true); sort_type = 1; sort();" class="btn btn-ghost flex-nowrap" :class="{'text-info': sort_type == 1}">
                                                <svg v-if="sort_type == 1 && sort_asc" class="pointer-events-none w-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 16L6 10H18L12 16Z"></path></svg>
                                                <svg v-if="sort_type == 1 && !sort_asc" class="pointer-events-nonew-4 h-4 stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 8L18 14H6L12 8Z"></path></svg>
                                                <label class="pointer-events-none">Text</label>
                                            </button>
                                        </th>
                                        <th class="hidden text-center flex-row items-center justify-center">
                                            <DotsHorizontalIcon :size="16" class="pointer-events-none" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="font-semibold">
                                    <template v-for="(item, index) in admin_data.starters">
                                        <AdminStarterItem
                                        v-if="!starters_search_text || (starters_search_text && ( item.text.toLowerCase().includes(starters_search_text.toLowerCase()) ))"
                                        @delete_item="delete_admin_item" @edit_item="show_add_edit_dialog" :item="item" :index="sort_asc ? (index + 1) : (admin_data.starters.length - index)" :is_mobile="false"
                                        />
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <NoItemsFoundComponent v-else />
                    </div>
                </div>

                <!-- tab 10 - more -->
                <input v-model="current_tab" value="10" type="radio" name="admin_tabs" aria-label="More" role="tab" class="tab w-fit whitespace-nowrap hidden text-base" />
                <div role="tabpanel" class="tab-content bg-base-100 border-base-300 rounded-box p-6 w-full">
                    <!-- email settings -->
                    <div class="flex flex-col items-center justify-start w-full gap-2">
                        <div class="flex flex-row items-center justify-between gap-2 w-full p-3 rounded-lg bg-base-200">
                            <p class="text-2xl text-gray-800 font-bold">Email Settings</p>
                            <div class="tooltip" data-tip="Save changes">
                                <button @click="save_email_settings" class="btn btn-primary btn-sm flex flex-row items-center justify-center gap-2">
                                    <ContentSaveIcon :size="20" class="pointer-events-none" />
                                    Save
                                </button>
                            </div>
                        </div>
                        <!-- first email subject -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left mt-2 text-gray-700">The subject of the first email</label>
                            <input v-model="admin_data.settings.first_email_title" type="text" class="input input-bordered w-full">
                        </div>
                        <!-- first email text with reagent -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left mt-4 text-gray-700">The text of the first email with reagent</label>
                            <textarea rows="12" v-model="admin_data.settings.first_email_with_reagent_text" class="textarea textarea-bordered w-full text-base"></textarea>
                        </div>
                        <!-- first email text without reagent -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left mt-4 text-gray-700">The text of the first email without reagent</label>
                            <textarea rows="12" v-model="admin_data.settings.first_email_without_reagent_text" class="textarea textarea-bordered w-full text-base"></textarea>
                        </div>
                        <div class="divider"></div>
                        <!-- second email subject -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left text-gray-700">The subject of the second email</label>
                            <input v-model="admin_data.settings.second_email_title" type="text" class="input input-bordered w-full text-base">
                        </div>
                        <!-- second email text -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left mt-4 text-gray-700">The text of the second email</label>
                            <textarea rows="12" v-model="admin_data.settings.second_email_text" class="textarea textarea-bordered w-full text-base"></textarea>
                        </div>
                        <div class="divider"></div>
                        <!-- third email subject -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left text-gray-700">The subject of the third email</label>
                            <input v-model="admin_data.settings.third_email_title" type="text" class="input input-bordered w-full text-base">
                        </div>
                        <!-- third email text -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left mt-4 text-gray-700">The text of the third email</label>
                            <textarea rows="12" v-model="admin_data.settings.third_email_text" class="textarea textarea-bordered w-full text-base"></textarea>
                        </div>
                        <div class="divider"></div>
                        <!-- admin mail -->
                        <div class="flex flex-col items-center justify-start w-full gap-2" v-if="admin_data.settings">
                            <label class="text-base w-full font-bold text-left text-gray-700">Admin email address:</label>
                            <input v-model="admin_data.settings.admin_email_address" type="text" class="input input-bordered w-full text-base">
                        </div>
                    </div>
                    <!-- landing page cards -->
                    <div class="flex flex-col items-center justify-start w-full gap-4 mt-8">
                        <div class="flex flex-row items-center justify-between gap-2 w-full p-3 rounded-lg bg-base-200">
                            <p class="text-2xl text-gray-800">Landing Page Cards</p>
                            <div class="tooltip" data-tip="Save changes">
                                <button @click="save_landing_page_cards_settings" class="btn btn-sm btn-primary flex flex-row items-center justify-center gap-2">
                                    <ContentSaveIcon :size="20" class="pointer-events-none" />
                                    Save
                                </button>
                            </div>
                        </div>
                        <div class="grid grild-cols-1 md:grid-cols-4 w-full gap-4" v-if="admin_data.landing_page_cards && admin_data.landing_page_cards.length > 0">
                            <template v-for="(item, index) in admin_data.landing_page_cards" :key="index">
                                <div class="flex flex-col items-center justify-between gap-2 w-full max-h-[350px] h-full">
                                    <p class="text-md text-gray-700 w-full text-left font-semibold">{{ item.string_id.replaceAll('l', '').replaceAll('_', ' ') + ':' }}</p>
                                    <img :id="item.string_id + '_img_prev'" class="w-full h-48 object-cover" src="@/assets/img/image-not-found.jpg" :alt="'Card ' + (index+1).toString() + ' image not found'" v-if="!item.image">
                                    <img :id="item.string_id + '_img'" class="w-full h-48 object-cover" :src="this.$store.state.base_url + item.image" :alt="item.alt_text ?? ''" v-if="item.image">
                                    <p class="text-md text-gray-700 w-full text-left font-semibold">Image description:</p>
                                    <input :id="item.string_id + '_text'" type="text" maxlength="150" class="input input-bordered w-full input-sm text-base" v-model="item.alt_text">
                                    <input :id="item.string_id" @change="landing_cards_images_input_change" accept=".jpg,.jpeg,.png,.webp" type="file" class="file-input file-input-bordered w-full" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <!-- video guid -->
                    <div class="flex flex-col items-center justify-start w-full gap-4 mt-8">
                        <div class="flex flex-row items-center justify-between gap-2 w-full p-3 rounded-lg bg-base-200">
                            <p class="text-2xl text-gray-800">Video guide</p>
                            <div class="tooltip" data-tip="Save video guide">
                                <button @click="save_video_guide_settings" class="btn btn-sm btn-primary flex flex-row items-center justify-center gap-2">
                                    <ContentSaveIcon :size="20" class="pointer-events-none" />
                                    Save
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-row items-center justify-start w-full">
                            <div class="flex flex-col items-start justify-center gap-2 w-full max-h-[350px] h-full">
                                <video type="video/mp4" controls class="w-full md:w-[500px] object-cover aspect-video" :src="admin_data.settings.video_guide" v-if="admin_data.settings && admin_data.settings.video_guide && video_guide_preview"></video>
                                <video type="video/mp4" controls class="w-full md:w-[500px] object-cover aspect-video" :src="this.$store.state.base_url + admin_data.settings.video_guide" v-if="admin_data.settings && admin_data.settings.video_guide && !video_guide_preview"></video>
                                <img src="@/assets/img/video-not-found.jpg" class="w-auto aspect-square h-48 object-cover" alt="Video guide not found'" v-if="!admin_data.settings || !admin_data.settings.video_guide">
                                <input id="video_guide_input" @change="video_guide_input_change" accept=".mp4" type="file" class="file-input file-input-bordered" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ConfirmationDialog ref="confirmation_dialog" @callback="confirmation_dialog_callback" />
    <AddEditDialog ref="add_edit_dialog" @callback="add_edit_dialog_callback" />
    <InterviewDetailsDialog ref="interview_details_dialog" />

</template>

<script>
import axios from 'axios';
import NoItemsFoundComponent from '@/components/no-items-found-component.vue';
import AdminPersonItem from '@/components/admin-person-item.vue';
import AdminAdvertiseItem from '@/components/admin-advertise-item.vue';
import AdminInterviewItem from '@/components/admin-interview-item.vue';
import AdminContentCategoryItem from '@/components/admin-content-category-item.vue';
import AdminArtistCategoryItem from '@/components/admin-artist-category-item.vue';
import AdminStarterItem from '@/components/admin-starter-item.vue';
import AdminInterviewQuestionsItem from '@/components/admin-interview-questions-item.vue';
import AdminSubscriberItem from '@/components/admin-subscriber-item.vue';
import AdminInviteCodeItem from '@/components/admin-invite-code-item.vue';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import AddEditDialog from '@/components/add-edit-dialog.vue';
import InterviewDetailsDialog from '@/components/interview-details-dialog.vue';
import Logo from '@/components/logo.vue';
// icons
import LogoutIcon from 'vue-material-design-icons/Logout.vue';
import DotsHorizontalIcon from 'vue-material-design-icons/DotsHorizontal.vue';
import RefreshIcon from 'vue-material-design-icons/Refresh.vue';
import ContentSaveIcon from 'vue-material-design-icons/ContentSave.vue';
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import PlusIcon from 'vue-material-design-icons/Plus.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';

import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical.vue';
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew.vue';

export default {
    name: 'Admin',
    components: {
        NoItemsFoundComponent,
        AdminPersonItem,
        AdminAdvertiseItem,
        AdminInterviewItem,
        AdminContentCategoryItem,
        AdminArtistCategoryItem,
        AdminStarterItem,
        AdminInviteCodeItem,
        AdminInterviewQuestionsItem,
        AdminSubscriberItem,
        ConfirmationDialog,
        AddEditDialog,
        InterviewDetailsDialog,
        Logo,
        // icons
        LogoutIcon,
        DotsHorizontalIcon,
        RefreshIcon,
        ContentSaveIcon,
        DeleteIcon,
        PlusIcon,
        PencilIcon,
        DotsVerticalIcon,
        OpenInNewIcon,
    },
    data() {
        return {
            logo_size: 'tiny',
            logo_mobile_size: 'tiny',
            current_tab: 0,
            sort_type: 0,
            sort_asc: true,
            interview_search_text: '',
            nominate_search_text: '',
            invite_codes_search_text: '',
            content_category_search_text: '',
            artist_category_search_text: '',
            interview_q_1_search_text: '',
            interview_q_2_search_text: '',
            subscribers_search_text: '',
            starters_search_text: '',
            advertise_search_text: '',
            video_guide_preview: false,
            admin_data: {
                person: [],
                interview_data: [],
                settings: {
                    // email settings
                    first_email_title: '',
                    first_email_with_reagent_text: '',
                    first_email_without_reagent_text: '',
                    second_email_title: '',
                    second_email_text: '',
                    third_email_title: '',
                    third_email_text: '',
                    admin_email_address: '',
                    // video guide
                    video_guide: '',
                },
                content_categories: [],
                artist_categories: [],
                interview_questions_1: [],
                interview_questions_2: [],
                starters: [],
                landing_page_cards: [
                    {string_id: '', images: '', alt_text: '', slug: ''},
                    {string_id: '', images: '', alt_text: '', slug: ''},
                    {string_id: '', images: '', alt_text: '', slug: ''},
                    {string_id: '', images: '', alt_text: '', slug: ''},
                    {string_id: '', images: '', alt_text: '', slug: ''},
                    {string_id: '', images: '', alt_text: '', slug: ''},
                    {string_id: '', images: '', alt_text: '', slug: ''},
                ],
            },
        }
    },
    mounted() {
        this.awake();
    },
    methods: {
        // awake
        async awake() {
            if(this.$store.state.is_authenticated == false)
            {
                this.$router.push({ path: '/admin-login' });
                return false;
            }
            else
            {
                if(this.$store.state.login_var == true)
                {
                    await this.$store.dispatch('addToast', {msg: 'Welcome, you are logged into your account', type: 'success'});
                    this.$store.state.login_var = false;
                }
            }
            document.title = 'History Of Colors - Admin Panel';
            this.refreshItems();
        },
        // refresh items
        async refreshItems() {
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/get/', {token: this.$store.state.auth_token} , this.$store.state.axios_config)
                .then(response => {
                    this.admin_data = response.data;
                    if(!this.admin_data.settings)
                        this.admin_data.settings = {first_email_title: '', first_email_with_reagent_text: '', first_email_without_reagent_text: '', second_email_title: '', second_email_text: ''};
                    this.$store.dispatch('addToast', {msg: 'Information has been updated', type: 'success'});
                })
                .catch(error => {
                    if(error.response.status == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.status == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    console.log(error);
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        // confirmation dialog callback
        async confirmation_dialog_callback(callback_data) {
            if(callback_data)
            {
                if((callback_data.type == 'delete_subscribers_data' || callback_data.type == 'delete_person' || callback_data.type == 'delete_invite_code' || callback_data.type == 'delete_interview_data' || callback_data.type == 'delete_content_category' || callback_data.type == 'delete_artist_category' || callback_data.type == 'delete_interview_questions_1' || callback_data.type == 'delete_interview_questions_2') && callback_data.data)
                {
                    this.$store.state.is_loading = true;
                    await axios.post('/api/v1/data/actions/', {request_type: 'delete', data_type: String(callback_data.type).replace('delete_', ''), id: callback_data.data, token: this.$store.state.auth_token} , this.$store.state.axios_config)
                        .then(response => {
                            this.admin_data = response.data;
                            this.$store.dispatch('addToast', {msg: 'Information deleted', type: 'success'});
                        })
                        .catch(error => {
                            if(error.response.status == 403)
                                this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                            else if(error.response.status == 404)
                                this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                            else
                                this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                            console.log(error);
                        });
                    setTimeout(() => {
                        this.$store.state.is_loading = false;
                    }, 500);
                }
            }
        },
        // add edit dialog callback
        show_add_edit_dialog(_action_type, _content_type, _itemData=null) {
            this.$refs.add_edit_dialog.show_dialog(_action_type, _content_type, _itemData);
        },
        // add edit dialog callback
        add_edit_dialog_callback(_data) {
            if(_data)
                this.admin_data = _data;
        },
        // edit interview question item
        edit_interview_question_item(question_type, _data) {
            if(_data)
            {
                _data.content_categories = this.admin_data.content_categories;
                this.show_add_edit_dialog('edit', 'interview_question_' + question_type, _data);
            }
        },
        // send email dialog callback
        send_mail_dialog_callback(_data) {
            if(_data)
                this.admin_data = _data;
        },
        // show interview details dialog
        open_interview_details_dialog(_data) {
            this.$refs.interview_details_dialog.show_dialog(_data);
        },
        // save email settings
        async save_email_settings() {
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/actions/', {request_type: 'save_email_settings', email_settings: this.admin_data.settings, token: this.$store.state.auth_token} , this.$store.state.axios_config)
                .then(response => {
                    this.admin_data = response.data;
                    this.$store.dispatch('addToast', {msg: 'Changes saved', type: 'success'});
                })
                .catch(error => {
                    if(error.response.status == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.status == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    console.log(error);
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        // save landing page cards settings
        async save_landing_page_cards_settings() {
            var formData = new FormData();
            for(let i=0;i<7;i++)
            {
                let item = document.getElementById('lcard_' + (i + 1).toString());
                let item_text = document.getElementById('lcard_' + (i + 1).toString() + '_text').value;

                if(item.files.length > 0 && !item_text)
                {
                    await this.$store.dispatch('addToast', {msg: 'Please write a short descriptive text for the selected photo', type: 'error'});
                    return;
                }

                if(item_text && item.files.length == 0 && (!this.admin_data.landing_page_cards || !this.admin_data.landing_page_cards[i].image))
                {
                    await this.$store.dispatch('addToast', {msg: 'Your chosen card does not have a photo and its text cannot be changed, please choose a photo for the desired card', type: 'error'});
                    return;
                }

                if(item_text)
                    formData.append('ltext_' + (i + 1).toString(), item_text);
                if(item.files.length > 0)
                    formData.append('lcard_' + (i + 1).toString(), item.files[0]);
            }
            formData.append('request_type', 'save_landing_page_cards_settings');
            formData.append('token', this.$store.state.auth_token);
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/actions/', formData, this.$store.state.axios_config)
                .then(response => {
                    this.admin_data = response.data;
                    this.$store.dispatch('addToast', {msg: 'Changes saved', type: 'success'});
                })
                .catch(error => {
                    if(error.response.status == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.status == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    if(this.$store.state.is_debug)
                    {
                        console.log(error);
                        if(error.data)
                            console.log(error.data);
                    }
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        // save video guide settings
        async save_video_guide_settings() {
            if(document.getElementById('video_guide_input').files.length == 0)
            {
                this.$store.dispatch('addToast', {msg: 'Please select the video first', type: 'error'});
                return;
            }
            var formData = new FormData();
            formData.append('video_guide', document.getElementById('video_guide_input').files[0]);
            formData.append('request_type', 'save_video_guide');
            formData.append('token', this.$store.state.auth_token);
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/actions/', formData, this.$store.state.axios_config)
                .then(response => {
                    this.admin_data = response.data;
                    this.video_guide_preview = false;
                    this.$store.dispatch('addToast', {msg: 'Changes saved', type: 'success'});
                })
                .catch(error => {
                    if(error.response.status == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.status == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    console.log(error);
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        // video guide input onchange
        video_guide_input_change(event) {
            const [file] = event.target.files
            if (file) {
                this.admin_data.settings.video_guide = URL.createObjectURL(file);
                this.video_guide_preview = true;
            }
        },
        // landing cards images input onchange
        landing_cards_images_input_change(event) {
            const [file] = event.target.files
            if (file) {
                if(document.getElementById(event.target.id + '_img'))
                    document.getElementById(event.target.id + '_img').src = URL.createObjectURL(file);
                if(document.getElementById(event.target.id + '_img_prev'))
                    document.getElementById(event.target.id + '_img_prev').src = URL.createObjectURL(file);
            }
        },
        // delete item
        delete_admin_item(_type, item_id, data_2=null) {
            if(item_id)
            {
                if(_type == 'person')
                {
                    // delete person
                    this.$refs.confirmation_dialog.show_dialog(item_id, 'delete_person', 'Are you sure to delete this information?', 'Delete', 'delete');
                }
                else if(_type == 'invite_code')
                {
                    // delete invite code
                    this.$refs.confirmation_dialog.show_dialog(item_id, 'delete_invite_code', 'Are you sure to delete this information?', 'Delete', 'delete');
                }
                else if(_type == 'content_category')
                {
                    // delete content category
                    this.$refs.confirmation_dialog.show_dialog(item_id, 'delete_content_category', 'Are you sure to delete this content category?', 'Delete', 'delete');
                }
                else if(_type == 'artist_category')
                {
                    // delete artist category
                    this.$refs.confirmation_dialog.show_dialog(item_id, 'delete_artist_category', 'Are you sure to delete this artist category?', 'Delete', 'delete');
                }
                else if(_type == 'interview_questions' && (data_2 == 1 || data_2 == 2))
                {
                    // delete interview questions
                    this.$refs.confirmation_dialog.show_dialog(item_id, 'delete_interview_questions_' + (_type == 1 ? '1' : '2'), 'Are you sure to delete this interview question?', 'Delete', 'delete');
                }
                else if(_type == 'interview_data')
                {
                    // delete interview data
                    this.$refs.confirmation_dialog.show_dialog(item_id, 'delete_interview_data', 'Are you sure to delete this information?', 'Delete', 'delete');
                }
                else if(_type == 'subscribers')
                {
                    // delete interview data
                    this.$refs.confirmation_dialog.show_dialog(item_id, 'delete_subscribers_data', 'Are you sure to delete this information?', 'Delete', 'delete');
                }
            }
        },
        // logout from admin account
        logout() {
            this.$store.state.auth_token = '';
            this.$store.state.is_authenticated = false;
            this.$store.state.logout_var = true;
            localStorage.removeItem('user_token');
            localStorage.removeItem('user_login_time');
            localStorage.removeItem('user_expire');
            this.$router.push({ path: '/admin-login/' });
        },
        // sort items
        sort() {
            // sort interview data
            if(this.current_tab == 0)
            {
                if(this.admin_data && this.admin_data.interview_data && this.admin_data.interview_data.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.person.name).localeCompare(second.person.name);
                        else if(_type == 2)
                            return ('' + first.person.email).localeCompare(second.person.email);
                        else if(_type == 3)
                            return second.views_count - first.views_count;
                    };
                    this.admin_data.interview_data.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.interview_data.reverse();
                }
            }
            // sort person data
            else if(this.current_tab == 1)
            {
                if(this.admin_data && this.admin_data.person && this.admin_data.person.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.name).localeCompare(second.name);
                        else if(_type == 2)
                            return ('' + first.email).localeCompare(second.email);
                        else if(_type == 3)
                            return ('' + first.your_name).localeCompare(second.your_name);
                    };
                    this.admin_data.person.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.person.reverse();
                }
            }
            // sort invitation code data
            else if(this.current_tab == 2)
            {
                if(this.admin_data && this.admin_data.invite_codes && this.admin_data.invite_codes.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.person.name).localeCompare(second.person.name);
                        else if(_type == 2)
                            return ('' + first.person.email).localeCompare(second.person.email);
                        else if(_type == 3)
                            return second.create_time - first.create_time;
                    };
                    this.admin_data.invite_codes.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.invite_codes.reverse();
                }
            }
            // sort content categories data
            else if(this.current_tab == 3)
            {
                if(this.admin_data && this.admin_data.content_categories && this.admin_data.content_categories.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.name).localeCompare(second.name);
                    };
                    this.admin_data.content_categories.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.content_categories.reverse();
                }
            }
            // sort artist categories data
            else if(this.current_tab == 4)
            {
                if(this.admin_data && this.admin_data.artist_categories && this.admin_data.artist_categories.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.name).localeCompare(second.name);
                    };
                    this.admin_data.artist_categories.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.artist_categories.reverse();
                }
            }
            // sort interview questions 1 data
            else if(this.current_tab == 5)
            {
                if(this.admin_data && this.admin_data.interview_questions_1 && this.admin_data.interview_questions_1.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.category.name).localeCompare(second.category.name);
                        else if(_type == 2)
                            return ('' + first.name).localeCompare(second.name);
                    };
                    this.admin_data.interview_questions_1.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.interview_questions_1.reverse();
                }
            }
            // sort interview questions 2 data
            else if(this.current_tab == 6)
            {
                if(this.admin_data && this.admin_data.interview_questions_2 && this.admin_data.interview_questions_2.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.category.name).localeCompare(second.category.name);
                        else if(_type == 2)
                            return ('' + first.name).localeCompare(second.name);
                    };
                    this.admin_data.interview_questions_2.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.interview_questions_2.reverse();
                }
            }
            // sort subscribers data
            else if(this.current_tab == 7)
            {
                if(this.admin_data && this.admin_data.subscribers && this.admin_data.subscribers.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.email).localeCompare(second.email);
                    };
                    this.admin_data.subscribers.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.subscribers.reverse();
                }
            }
            // sort advertise data
            else if(this.current_tab == 8)
            {
                if(this.admin_data && this.admin_data.advertise && this.admin_data.advertise.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.name).localeCompare(second.name);
                        else if(_type == 2)
                            return ('' + first.company_name).localeCompare(second.company_name);
                        else if(_type == 3)
                            return ('' + first.email).localeCompare(second.email);
                    };
                    this.admin_data.advertise.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.advertise.reverse();
                }
            }
            // sort starters data
            else if(this.current_tab == 9)
            {
                if(this.admin_data && this.admin_data.starters && this.admin_data.starters.length > 0)
                {
                    var sort_func = function(_type, first, second) {
                        if(_type == 0)
                            return second.id - first.id;
                        else if(_type == 1)
                            return ('' + first.text).localeCompare(second.text);
                    };
                    this.admin_data.starters.sort(sort_func.bind(null, this.sort_type));
                    if(!this.sort_asc)
                        this.admin_data.starters.reverse();
                }
            }
        },
    },
}
</script>
