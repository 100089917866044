import { createStore } from 'vuex'

export default createStore({
    state: {
        app_theme: 'light',
        use_dark: false,
        is_loading: false,
        is_authenticated: false,
        axios_config: { headers: { Authorization: 'Token 1167c138c5f984a3e505c437539a25a5df59d541' } },
        axios_config_multipart: { headers: { Authorization: 'Token 1167c138c5f984a3e505c437539a25a5df59d541', 'Content-Type': 'multipart/form-data' } },
        auth_token: '',
        login_var: false,
        logout_var: false,
        title: 'History Of Colors',
        toast_items: [],
        is_debug: true,
        base_url: '',
        base_data: null,
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        addToast(store, data) {
            let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
            if (data && data.msg) {
                store.state.toast_items.push({ id: uuid, message: data.msg, type: data.type ? data.type : 'info', timeout: data.timeout ? data.timeout : 3000 });
                return true;
            }
            return false;
        },
        removeToast(store, id = '') {
            if (id) {
                let items = store.state.toast_items;
                for (let i = 0; i < items.length; i++) {
                    if (items[i] && items[i].id == id) {
                        items.splice(i, 1);
                        store.state.toast_items = items;
                        return true;
                    }
                }
            }
            return false;
        },
        getToast(store, id = '') {
            if (id) {
                let items = store.state.toast_items;
                for (let i = 0; i < items.length; i++) {
                    if (items[i] && items[i].id == id) {
                        return { id: items[i].id, message: items[i].message, type: items[i].type, timeout: items[i].timeout }
                    }
                }
            }
            return null;
        },
    },
    modules: {
    }
})
